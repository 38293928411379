import React from 'react';
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
    const socialData = [
        // { link: "#0", name: "Dribbble." },
        // { link: "#0", name: "Behance." },
        // { link: "#0", name: "Linkedin." },
        { link: "https://www.facebook.com/profile.php?id=61557574045241" , name: "Facebook." },
        { link: "https://www.instagram.com/fsportlab/", name: "Instagram." },
    ];
    const googleMapsUrl = "https://www.google.com/maps/place/F+SportLab/@39.081812,17.1170568,17z/data=!3m1!4b1!4m6!3m5!1s0x134049a025618cf9:0xbe1d2716e2dbd1db!8m2!3d39.0818079!4d17.1219223!16s%2Fg%2F11vzmm040x?entry=ttu";
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Negozio</h5>
                    <p>
                <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                    Via Mario Nicoletta, 149 <br />
                    88900 Crotone KR <br />
                    Italy
                </a>
            </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contatto</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+393282427778" data-hover-text="+39 328 242 7778">
                            +39 328 242 7778
                        </a>
                    </p>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:info@fsportlab.com"
                            data-hover-text="info@fsportlab.com">info@fsportlab.com</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Seguici</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;